import classNames from "classnames";
import NovamaxImg from "../assets/deviceImg.png";
import NeodocsImg from "../assets/nedocsImg.png";
import AllegroImg from "../assets/allegroImg.png";
import { useTranslation } from "react-i18next";
import "./DeviceImage.scss";
import { useTooltip } from "../Tooltip";
import { useHover } from "@floating-ui/react";
import { SampledByDeviceType } from "models/DeviceModels";
import { Logger } from "../logger.model";
interface DeviceImageProps {
  className?: string;
  alt?: string;
  loading?: boolean;
  sampledByDevice?: number | null;
  logger?: Logger;
}

export function DeviceImage({
  sampledByDevice = SampledByDeviceType.NovaMax, // default value since sampledByDevice is introduced after NovaMax
  className,
  alt,
  loading,
  logger,
}: Readonly<DeviceImageProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "ui-components.DeviceImage" });
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    interactionProps: [useHover],
    placement: "right",
  });

  const deviceMapper = () => {
    switch (sampledByDevice) {
      case SampledByDeviceType.Neodocs:
        return {
          img: NeodocsImg,
          alt,
          tooltip: !alt ? <Tooltip>{t("neodocsAlt")}</Tooltip> : null,
        };
      case SampledByDeviceType.NovaMax:
        return {
          img: NovamaxImg,
          alt,
          tooltip: alt ? <Tooltip>{t("serialNumber", { serialNumber: alt })}</Tooltip> : null,
        };

      case SampledByDeviceType.Allegro:
        return {
          img: AllegroImg,
          alt,
          tooltip: alt ? <Tooltip>{t("serialNumber", { serialNumber: alt })}</Tooltip> : null,
        };

      // This is out default case for now
      default:
        logger?.error({
          msg: "DeviceImage: invalid sampledByDevice value",
          sampledByDevice,
        });
        return {
          img: undefined,
          alt: undefined,
          tooltip: null,
        };
    }
  };

  return (
    <figure className={classNames("DeviceImage", className, { "DeviceImage--loading": loading })}>
      {loading || !deviceMapper().img ? null : (
        <img
          src={deviceMapper().img}
          className="DeviceImage__src"
          alt={deviceMapper().alt}
          ref={ref}
          {...getReferenceProps()}
        />
      )}
      {deviceMapper().tooltip}
    </figure>
  );
}
