import "./Tooltip.scss";

import classNames from "classnames";
import {
  MutableRefObject,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from "react";

import {
  Placement,
  ReferenceType,
  Strategy,
  arrow,
  autoUpdate,
  offset,
  shift,
} from "@floating-ui/react-dom";

import { TooltipProps, TooltipType, arrowSizeType, usePlacementParams } from "./model";
import { ElementProps, FloatingContext, useFloating, useInteractions } from "@floating-ui/react";
import { IsNullOrUndefined } from "utils/guards";

export function useTooltip({
  placement = "top",
  mainAxis = 10,
  crossAxis,
  type = "grey",
  arrowSize = "small",
  interactionProps,
  className,
  showArrow,
}: PropsWithChildren<TooltipProps>) {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);
  const { arrowPosition, arrowAngle } = usePlacementParams(placement);

  const {
    x,
    y,
    refs,
    strategy,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    middleware: [
      offset({
        ...(crossAxis && { crossAxis }),
        mainAxis,
      }),
      shift(),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions(
    interactionProps?.map(f => f(context)),
  );

  const TooltipComp = useCallback(
    ({
      children,
      alwaysShown,
    }: PropsWithChildren<{
      alwaysShown?: boolean;
    }>) => {
      if (open === false && !!alwaysShown === false) {
        return null;
      }

      return (
        <div
          className={classNames("Tooltip", `Tooltip--${type}`, className)}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? "",
            left: x ?? "",
          }}
          {...getFloatingProps()}
        >
          {showArrow ? (
            <div
              className={classNames(
                "Tooltip__arrow",
                `Tooltip__arrow--${type}`,
                `Tooltip__arrow--${arrowSize}`,
                `Tooltip__arrow--${arrowPosition}`,
              )}
              ref={arrowRef}
              style={{
                left: arrowX != null ? `${arrowX}px` : "",
                top: arrowY != null ? `${arrowY}px` : "",
                transform: `rotate(${arrowAngle}deg)`,
              }}
            />
          ) : null}
          {children}
        </div>
      );
    },
    [
      open,
      type,
      className,
      refs.setFloating,
      strategy,
      y,
      x,
      getFloatingProps,
      showArrow,
      arrowSize,
      arrowPosition,
      arrowX,
      arrowY,
      arrowAngle,
    ],
  );

  return [refs.setReference, getReferenceProps, TooltipComp] as const;
}

interface UseGetTooltipProps {
  interactionProps?: ((context: FloatingContext<ReferenceType>) => ElementProps)[];
  placement?: Placement;
  mainAxis?: number;
  crossAxis?: number;
}

export function useGetTooltipProps({
  interactionProps,
  placement,
  mainAxis,
  crossAxis,
}: UseGetTooltipProps) {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const {
    x,
    y,
    refs,
    strategy,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    middleware: [
      offset({
        ...(crossAxis && { crossAxis }),
        mainAxis,
      }),
      shift(),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions(
    interactionProps?.map(f => f(context)),
  );

  return {
    open,
    x,
    y,
    refs,
    arrowRef,
    strategy,
    context,
    arrowX,
    arrowY,
    setOpen,
    getReferenceProps,
    getFloatingProps,
  };
}

interface TooltipComponentProps {
  children: React.ReactNode;
  type?: TooltipType;
  alwaysShown?: boolean;
  floatingProps: Record<string, unknown>;
  open: boolean;
  strategy: Strategy;
  arrowX?: number;
  arrowY?: number;
  arrowRef?: MutableRefObject<null>;
  arrowPlacement?: Placement;
  arrowSize?: arrowSizeType;
  x: number | undefined;
  y: number | undefined;
  className?: string;
}

export const TooltipComponent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<TooltipComponentProps>
>(
  (
    {
      children,
      alwaysShown,
      type,
      className,
      floatingProps,
      arrowPlacement,
      open,
      strategy,
      arrowRef,
      arrowSize = "small",
      arrowX,
      arrowY,
      x,
      y,
    },
    ref,
  ) => {
    const { arrowPosition, arrowAngle } = usePlacementParams(arrowPlacement);

    if (open === false && !!alwaysShown === false) {
      return null;
    }

    return (
      <div
        className={classNames("Tooltip", `Tooltip--${type}`, className)}
        ref={ref}
        style={{
          position: strategy,
          top: y ?? "",
          left: x ?? "",
        }}
        {...floatingProps}
      >
        {arrowRef && arrowPosition ? (
          <div
            className={classNames(
              "Tooltip__arrow",
              `Tooltip__arrow--${type}`,
              `Tooltip__arrow--${arrowSize}`,
              `Tooltip__arrow--${arrowPosition}`,
            )}
            ref={arrowRef}
            style={{
              left: IsNullOrUndefined(arrowX) ? "" : `${arrowX}px`,
              top: IsNullOrUndefined(arrowY) ? "" : `${arrowY}px`,
              transform: `rotate(${arrowAngle}deg)`,
            }}
          />
        ) : null}
        {children}
      </div>
    );
  },
);
